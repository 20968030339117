import { NgModule } from "@angular/core";
import { RouterModule, Routes, UrlMatcher, UrlSegment } from "@angular/router";
import { cardIdMatcher } from "src/models/Card";
import { UserType } from "src/models/User";
import { AuthGuard } from "./services/auth/auth.guard";

/**
 * Try to match the URL to card ID format
 */
const customerCardMatcher: UrlMatcher | null = (url) => {
  if (url.length !== 1) {
    return null;
  }

  if (cardIdMatcher(url[0].path)) {
    return {
      consumed: url,
      posParams: {
        id: new UrlSegment(url[0].path, {}),
      },
    };
  }

  return null;
};

/**
 * Try to match the URL to card ID format
 */
const adminCardMatcher = (path: string): UrlMatcher => {
  return (url: UrlSegment[]) => {
    if (url.length !== 2) {
      return null;
    }

    if (url[0].path === path && cardIdMatcher(url[1].path)) {
      return {
        consumed: url,
        posParams: {
          id: new UrlSegment(url[1].path, {}),
        },
      };
    }

    return null;
  };
};

export const routes: Routes = [
  {
    path: "",
    loadComponent: () =>
      import("src/app/pages/home/home.component").then(
        (mod) => mod.HomeComponent
      ),
  },
  {
    path: "jak-to-funguje",
    loadComponent: () =>
      import("src/app/pages/how-to/how-to.component").then(
        (mod) => mod.HowToComponent
      ),
  },
  {
    path: "pobocky",
    loadComponent: () =>
    import("src/app/pages/branches/branches.component").then(
        (mod) => mod.BranchesPage
      ),
  },
  {
    path: "prihlasit",
    loadComponent: () =>
      import("src/app/pages/login/login.component").then(
        (mod) => mod.LoginComponent
      ),
  },
  {
    path: "email-auth",
    loadComponent: () =>
      import("src/app/pages/email-auth/email-auth.component").then(
        (mod) => mod.EmailAuthComponent
      ),
  },
  {
    path: "aktivovat",
    loadComponent: () =>
      import("src/app/pages/activate/activate.component").then(
        (mod) => mod.ActivateComponent
      ),
  },
  {
    path: "voucher",
    loadComponent: () =>
      import("src/app/pages/voucher/voucher.component").then(
        (mod) => mod.VoucherComponent
      ),
  },
  {
    path: "admin",
    loadComponent: () =>
      import("src/app/pages/admin/admin.component").then(
        (mod) => mod.AdminComponent
      ),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
    data: {
      roles: [UserType.cashier],
    },
    children: [
      {
        path: "recent",
        redirectTo: "/reports",
      },
      {
        matcher: adminCardMatcher("card"),
        loadComponent: () =>
          import("src/app/pages/admin/card/admin-card.component").then(
            (mod) => mod.AdminLogCardComponent
          ),
      },
      {
        matcher: adminCardMatcher("create-card"),
        loadComponent: () =>
          import(
            "src/app/pages/admin/create-card/admin-create-card.component"
          ).then((mod) => mod.AdminCreateCardComponent),
      },
    ],
  },
  {
    path: "tarify",
    loadComponent: () =>
      import(
        "src/app/pages/plans/plans.component"
      ).then((mod) => mod.PlansComponent),
  },
  {
    path: "objednat/:id",
    loadComponent: () =>
      import("src/app/pages/order-product/order-product.component").then(
        (mod) => mod.OrderProductComponent
      ),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
    data: {
      roles: [UserType.customer],
    },
  },
  {
    matcher: customerCardMatcher,
    loadComponent: () =>
      import("src/app/pages/card/card.component").then(
        (mod) => mod.CardComponent
      ),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
  },
  {
    path: 'soutez',
    loadComponent: () =>
      import('src/app/pages/contest/contest.component').then(
        (mod) => mod.ContestComponent
      ),
  },
  {
    path: "customer/:id",
    loadComponent: () =>
      import("src/app/pages/customer-status/customer-status.component").then(
        (mod) => mod.CustomerStatusComponent
      ),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
    data: {
      roles: [UserType.cashier],
    },
  },
  {
    path: "qr",
    loadComponent: () =>
      import("src/app/pages/qr-reader/qr-reader.component").then(
        (mod) => mod.QrReaderComponent
      ),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
    data: {
      roles: [UserType.cashier],
    },
  },
  {
    path: "soutez",
    loadComponent: () =>
      import("src/app/pages/contest/contest.component").then(
        (mod) => mod.ContestComponent
      ),
  },
  {
    path: "customer/:id",
    loadComponent: () =>
      import("src/app/pages/customer-status/customer-status.component").then(
        (mod) => mod.CustomerStatusComponent
      ),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
    data: {
      roles: [UserType.cashier],
    },
  },
  {
    path: "kontakt",
    loadComponent: () =>
      import("src/app/pages/contact/contact.component").then(
        (mod) => mod.ContactComponent
      ),
  },
  {
    path: "podminky",
    loadComponent: () =>
      import("src/app/pages/terms/terms.component").then(
        (mod) => mod.TermsComponent
      ),
  },
  {
    path: "soukromi",
    loadComponent: () =>
      import("src/app/pages/privacy/privacy.component").then(
        (mod) => mod.PrivacyComponent
      ),
  },
  {
    path: "chat",
    loadComponent: () =>
      import("src/app/components/chat/chat/chat.component").then(
        (mod) => mod.ChatComponent
      ),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
    data: {
      roles: [UserType.cashier],
    },
  },
  {
    path: "reports",
    loadComponent: () =>
      import("src/app/pages/reports/reports.component").then(
        (mod) => mod.ReportsComponent
      ),
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
    data: {
      roles: [UserType.cashier],
    },
    children: [
      {
        path: "",
        loadComponent: () =>
          import("src/app/pages/reports/used-cards/used-cards.component").then(
            (mod) => mod.UsedCardsComponent
          ),
        data: {
          label: "Načtené karty",
        },
      },
      {
        path: "sold-cards",
        loadComponent: () =>
          import("src/app/pages/reports/sold-cards/sold-cards.component").then(
            (mod) => mod.SoldCardsComponent
          ),
        data: {
          label: "Prodané karty",
        },
      },
      {
        path: "customers",
        loadComponent: () =>
          import("src/app/pages/reports/customers/customers.component").then(
            (mod) => mod.CustomersComponent
          ),
        data: {
          label: "Zákazníci",
        },
      },
      {
        path: "users",
        loadComponent: () =>
          import("src/app/pages/reports/users/users.component").then(
            (mod) => mod.UsersComponent
          ),
        data: {
          label: "Zákazníci bez karty",
        },
      },
      {
        path: "cashiers",
        loadComponent: () =>
          import("src/app/pages/reports/cashiers/cashiers.component").then(
            (mod) => mod.CashiersComponent
          ),
        data: {
          label: "Baristi",
        },
      },
      {
        path: "venues",
        loadComponent: () =>
          import("src/app/pages/reports/venues/venues.component").then(
            (mod) => mod.VenuesComponent
          ),
        data: {
          label: "Provozovny",
        },
      },
    ],
  },
  {
    path: "**",
    loadComponent: () =>
      import("src/app/pages/error404/error404.component").then(
        (mod) => mod.Error404Component
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: "reload",
      anchorScrolling: "enabled",
      scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
